import React from 'react';
import { parquetOptions } from '../data/parquetOptions';

function ParquetSelector({ selectedParquet, setSelectedParquet }) {
  return (
    <div className="parquet-list">
      {parquetOptions.map((option) => (
        <div key={option.id} className="parquet-option">
          <img
            src={option.imageUrl}
            alt={option.name}
            onClick={() => setSelectedParquet(option)}
            className={selectedParquet?.id === option.id ? 'selected' : ''}
          />
          <p>{option.name}</p>
        </div>
      ))}
    </div>
  );
}

export default ParquetSelector;
