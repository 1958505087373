import React, { useState, useRef } from 'react';
import { isIOS, isAndroid } from '../utils/deviceDetection';
import ParquetSelector from './ParquetSelector';

function Home() {
  const [selectedParquet, setSelectedParquet] = useState(null);
  const arQuickLookRef = useRef(null);

  const handleViewInRoom = () => {
    if (selectedParquet) {
      const { iosModelUrl, modelUrl } = selectedParquet;
      if (isIOS()) {
        // Folosirea AR Quick Look pentru iOS
        if (arQuickLookRef.current) {
          arQuickLookRef.current.click();
        }
      } else if (isAndroid()) {
        window.location.href = `intent://arvr.google.com/scene-viewer/1.0?file=${window.location.origin}${modelUrl}&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;end;`;
      } else {
        alert('AR nu este suportat pe acest dispozitiv.');
      }
    } else {
      alert('Selectează întâi modelul de parchet.');
    }
  };

  return (
    <div className="overflow">
      <h1>Selectează modelul de parchet</h1>
      <ParquetSelector selectedParquet={selectedParquet} setSelectedParquet={setSelectedParquet} />
      {selectedParquet && (
        <div>
          <a href={selectedParquet.iosModelUrl} ref={arQuickLookRef} rel="ar" style={{ display: 'none' }}>
            Open AR
          </a>
          <button className="view-in-room-button" onClick={handleViewInRoom}>
            Vezi în camera ta
          </button>
        </div>
      )}
    </div>
  );
}

export default Home;
