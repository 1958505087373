export const parquetOptions = [
  {
    id: 'parquet1',
    name: 'Parchet Clasic',
    imageUrl: 'Custom-Parquet-Wood-Floor-3-scaled.jpeg',
    modelUrl: '1.glb', // sau .glb pentru Android
    iosModelUrl: '1.usdz'
  },
  {
    id: 'chevron',
    name: 'Chevron',
    imageUrl: 'chevron.jpg',
    modelUrl: 'chevron.glb', // sau .glb pentru Android
    iosModelUrl: 'chevron.usdz'
  },
  {
    id: 'chantilly',
    name: 'Chantilly',
    imageUrl: 'chantilly.jpg',
    modelUrl: 'chantilly.glb', // sau .glb pentru Android
    iosModelUrl: 'chantilly.usdz'
  },
  {
    id: 'versailes',
    name: 'Versailes',
    imageUrl: 'versailes.webp',
    modelUrl: 'versailes.glb', // sau .glb pentru Android
    iosModelUrl: 'versailes.usdz'
  },

  {
    id: 'chalose',
    name: 'Chalose',
    imageUrl: 'chalose.jpeg',
    modelUrl: 'chalose.glb', // sau .glb pentru Android
    iosModelUrl: 'chalose.usdz'
  },
  {
    id: 'square-basket',
    name: 'Square Basket',
    imageUrl: 'square-basket.jpg',
    modelUrl: 'square-basket.glb', // sau .glb pentru Android
    iosModelUrl: 'square-basket.usdz'
  },
  {
    id: 'aremberg',
    name: 'Aremberg',
    imageUrl: 'aremberg.jpg',
    modelUrl: 'aremberg.glb', // sau .glb pentru Android
    iosModelUrl: 'aremberg.usdz'
  },
  {
    id: 'herringbone',
    name: 'Herringbone',
    imageUrl: 'herringbone.jpg',
    modelUrl: 'herringbone.glb', // sau .glb pentru Android
    iosModelUrl: 'herrincbone.usdz'
  },
  {
    id: 'checkerboard',
    name: 'Checkerboard',
    imageUrl: 'checkerboard.jpg',
    modelUrl: 'checkerboard.glb', // sau .glb pentru Android
    iosModelUrl: 'checkerboard.usdz'
  },
  {
    id: 'echelle',
    name: 'Echelle',
    imageUrl: 'echelle.jpg',
    modelUrl: 'echelle.glb', // sau .glb pentru Android
    iosModelUrl: 'echelle.usdz'
  },

];
